import React, {Component} from 'react';
import {Col, Row} from "react-flexbox-grid";
import Container from '../views/Container/Container';
import * as css from './index.css'
import * as strings from '../utils/strings'
import * as utils from '../utils/utils'
import BoxHome from '../components/BoxHome/BoxHome'
import * as globalCss from '../App.css';
import * as constants from '../utils/constants';
import box1_image from '../images/home/img_home_01.jpg';
import box2_image from '../images/home/img_home_02.jpg';
import box3_image from '../images/home/img_home_03.jpg';
import Wrapper from '../components/Wrapper/Wrapper'
import Logo from '../components/Logo/Logo';
import Cookies from 'universal-cookie';
import Placeholder from '../components/Placeholder/Placeholder'
import FetchedMetatags from './FetchedMetatags'
class Home extends Component {
    constructor(args) {
        super(args);
        this.state = {
            showSplash:null,
        }
    }
    componentDidMount = () =>{
        const fakeState = utils.deepCopy(this.state);
        const cookies = new Cookies();

        fakeState.showSplash = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
            && cookies.get(constants.COOKIE_SPLASH)==null;
        this.setState(fakeState);

        if(fakeState.showSplash){
            setTimeout(()=>{
                fakeState.showSplash = false;
                cookies.set(constants.COOKIE_SPLASH, true,{maxAge:99999999});

                this.setState(fakeState);
            }, 3000);
        }
    }

    render() {
        return (
            <div className="relative">
                <FetchedMetatags pagename={"homepage"}/>

                {this.state.showSplash === true?
                    <div className={"splash-biokeratin hiddenSplash"}>
                        <div className="blended-background">
                            <span className="ball-landing"/>
                            <div className="blendend"/>

                            <div className="content">
                                <div className="margin">
                                    <Logo/>
                                    <p className="title">
                                        {strings.stringsHome.title}
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    :""
                }

                {this.state.showSplash === false?
                    <Container history={this.props.history} fluid pageName="homepage" verticalAligned metatagsInChild>
                      <div className={'homepage_scroll'}>
                        <Row>
                            <Col xs={12} className="nopadding">
                                <div className="homeContainer">
                                    <h1 className="title hidden-xs" cascade="true">
                                        {strings.stringsHome.title}
                                    </h1>
                                    <div className="boxContainer">
                                        <BoxHome
                                            classBox="box1"
                                            link={constants.NAVIGATION_TROVA_IL_TUO_COLORE}
                                            title={strings.stringsHome.lbl_box_1_title}
                                            subtitle={strings.stringsHome.lbl_box_1}
                                            subtitle2={strings.stringsHome.lbl_box_1_2}
                                            imgBox={box1_image}/>

                                        <BoxHome
                                            classBox="box2"
                                            title={strings.stringsHome.lbl_box_2_title}
                                            subtitle={strings.stringsHome.lbl_box_2}
                                            subtitle2={strings.stringsHome.lbl_box_2_2}
                                            link={constants.NAVIGATION_PRODUCTS}
                                            imgBox={box2_image}/>

                                        <BoxHome
                                            classBox="box3"
                                            title={strings.stringsHome.lbl_box_3_title}
                                            subtitle={strings.stringsHome.lbl_box_3}
                                            subtitle2={strings.stringsHome.lbl_box_3_2}
                                            link={constants.NAVIGATION_CREA_IL_TUO_LOOK}
                                            imgBox={box3_image}/>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                      </div>
                    </Container>
                :""
                }
            </div>
        );
    }
}
export default Home;
