import React, {Component} from 'react';
import css from './BoxHome.css'
import {Col, Row} from "react-flexbox-grid";
import { stringsHome } from '../../utils/strings';
import * as utils from '../../utils/utils'
import * as constants from '../../utils/constants'
import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Placeholder from '../Placeholder/Placeholder'
class BoxHome extends Component {
    constructor(args) {
        super(args);
        this.state = {
            hover:false
        }
    }

    hover = (isHover) =>{
        const fakeState = utils.deepCopy(this.state)
        fakeState.hover = isHover;
        this.setState(fakeState)
    }



    render() {
        return (
            <AniLink duration={0.6} fade to={this.props.blocked?null:this.props.link}>
                <div cascade="true" className={"box " + (this.props.classBox) + (this.props.blocked?" blocked":"")} onMouseEnter={this.hover.bind(this,true)} onMouseLeave={this.hover.bind(this,false)}>
                    {this.props.blocked && <div className="blocked"/>}
                    {this.props.blocked && <span className="blockedText">{stringsHome.btn_box_vai_blocked}</span>}
                    <div className={"background " + (this.state.hover?"hover":"")} style={{background:"url("+this.props.imgBox+")"}} onLoad={this.onElementLoaded}/>
                    <div className="content-box">
                        <b><h3 className="title">{this.props.title}</h3></b>
                        <h1 className="subtitle">{this.props.subtitle}</h1>
                        <h1 className="subtitle">{this.props.subtitle2}</h1>
                        {!this.props.blocked && <button className="defaultButton hidden-xs">
                            {stringsHome.btn_box_vai}
                        </button>}
                    </div>
                </div>
            </AniLink>
            
        )
    }
}

export default BoxHome;