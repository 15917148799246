import "./Placeholder.css"

import React, {Component} from 'react';
import {Col, Row} from "react-flexbox-grid";

const Placeholder = (props) =>(
    props.loading?
        <span className="placeholder"/>
    :
        props.children
)

export default Placeholder;